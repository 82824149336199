.question{
    background-color: rgb(231, 238, 238);
}

.questions {
    height: auto !important;
}

.rotate-hor-center {
	-webkit-animation: rotate-hor-center 1s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both;
	        animation: rotate-hor-center 1s cubic-bezier(0.455, 0.030, 0.515, 0.955) infinite both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-10-23 3:36:42
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation rotate-hor-center
 * ----------------------------------------
 */
 @-webkit-keyframes rotate-hor-center {
    0% {
      -webkit-transform: rotateX(0);
              transform: rotateX(0);
    }
    100% {
      -webkit-transform: rotateX(-360deg);
              transform: rotateX(-360deg);
    }
  }
  @keyframes rotate-hor-center {
    0% {
      -webkit-transform: rotateX(0);
              transform: rotateX(0);
    }
    100% {
      -webkit-transform: rotateX(-360deg);
              transform: rotateX(-360deg);
    }
  }
  

  .rotate-center {
	-webkit-animation: rotate-center 1.2s cubic-bezier(0.550, 0.085, 0.680, 0.530) infinite both;
	        animation: rotate-center 1.2s cubic-bezier(0.550, 0.085, 0.680, 0.530) infinite both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-10-23 3:38:7
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation rotate-center
 * ----------------------------------------
 */
 @-webkit-keyframes rotate-center {
    0% {
      -webkit-transform: rotate(0);
              transform: rotate(0);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
  @keyframes rotate-center {
    0% {
      -webkit-transform: rotate(0);
              transform: rotate(0);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }

  
  
.summ{
    background-color: rgb(112, 33, 99);
    border-radius: 15px;
    width: 150px;
    height: 150px;
    -webkit-animation: scale-up-ver-bottom 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation: scale-up-ver-bottom 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  }

.summ div{
    background-color: rgb(238, 217, 27);
    border-radius: 15px 15px 0 0;
    width: 100%;
   
}


/* ----------------------------------------------
 * Generated by Animista on 2022-10-23 4:55:31
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-up-ver-bottom
 * ----------------------------------------
 */
 @-webkit-keyframes scale-up-ver-bottom {
    0% {
      -webkit-transform: scaleY(0.4);
              transform: scaleY(0.4);
      -webkit-transform-origin: 0% 100%;
              transform-origin: 0% 100%;
    }
    100% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      -webkit-transform-origin: 0% 100%;
              transform-origin: 0% 100%;
    }
  }
  @keyframes scale-up-ver-bottom {
    0% {
      -webkit-transform: scaleY(0.4);
              transform: scaleY(0.4);
      -webkit-transform-origin: 0% 100%;
              transform-origin: 0% 100%;
    }
    100% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      -webkit-transform-origin: 0% 100%;
              transform-origin: 0% 100%;
    }
  }
  